// extracted by mini-css-extract-plugin
export var alternativeButtonsContainer = "simpleProduct-module--alternative-buttons-container--19962";
export var bgGrey100 = "simpleProduct-module--bg-grey-100--d50b8";
export var bgGrey150 = "simpleProduct-module--bg-grey-150--6166a";
export var bgGrey200 = "simpleProduct-module--bg-grey-200--160cd";
export var bgGrey300 = "simpleProduct-module--bg-grey-300--c815b";
export var bgGrey400 = "simpleProduct-module--bg-grey-400--e7898";
export var bgGrey500 = "simpleProduct-module--bg-grey-500--b8832";
export var bgGrey600 = "simpleProduct-module--bg-grey-600--c1ac9";
export var bgGrey700 = "simpleProduct-module--bg-grey-700--f6f01";
export var bgGrey800 = "simpleProduct-module--bg-grey-800--871b2";
export var bgGrey900 = "simpleProduct-module--bg-grey-900--dcb2f";
export var breadcrumb = "simpleProduct-module--breadcrumb--d8751";
export var breadcrumbContainer = "simpleProduct-module--breadcrumb-container--0c229";
export var descriptionArea = "simpleProduct-module--description-area--7adb1";
export var detailsTable = "simpleProduct-module--detailsTable--bdaf9";
export var imageArea = "simpleProduct-module--image-area--3f184";
export var introContainer = "simpleProduct-module--intro-container--e5c56";
export var offerIcon = "simpleProduct-module--offer-icon--8d714";
export var priceContainer = "simpleProduct-module--price-container--479cf";
export var priceOnSale = "simpleProduct-module--price-on-sale--36ec3";
export var priceRegular = "simpleProduct-module--price-regular--f4adc";
export var priceTag = "simpleProduct-module--price-tag--1b7f7";
export var productBrand = "simpleProduct-module--product-brand--2263c";
export var productName = "simpleProduct-module--product-name--a49d0";
export var productTag = "simpleProduct-module--product-tag--2e250";
export var sliderWrapper = "simpleProduct-module--slider-wrapper--f62bc";
export var specificationsContainer = "simpleProduct-module--specifications-container--954cc";
export var tagContainer = "simpleProduct-module--tag-container--3e916";
export var textGrey100 = "simpleProduct-module--text-grey-100--5d254";
export var textGrey150 = "simpleProduct-module--text-grey-150--a8991";
export var textGrey200 = "simpleProduct-module--text-grey-200--94e08";
export var textGrey300 = "simpleProduct-module--text-grey-300--29f09";
export var textGrey400 = "simpleProduct-module--text-grey-400--fef4b";
export var textGrey500 = "simpleProduct-module--text-grey-500--8f57c";
export var textGrey600 = "simpleProduct-module--text-grey-600--249e0";
export var textGrey700 = "simpleProduct-module--text-grey-700--6293b";
export var textGrey800 = "simpleProduct-module--text-grey-800--31e10";
export var textGrey900 = "simpleProduct-module--text-grey-900--274f9";