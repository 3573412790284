import { graphql } from "gatsby"
import { prop } from "ramda"
import React, { useEffect, useRef } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { navigate } from "gatsby"
import { basketSelectors, basketActions } from "../state/basket"
import { dataLayerUpdate } from "../api/dataLayer/gaDataLayer"
import {
  getBreadcrumbDataFromCategoryObj,
  getSliderImages,
  getSpecs,
  getSimpleProductColor,
  getProductSize,
  getProductBrand,
  getRequiredProductDataForGA,
  getProductAttributeValue,
  getProductPrimaryCategory,
  buildUsedBikeFeaturedBoxes
} from "../utils/productsHelper"
import {
  getSeoAvailiability,
  makeSeoProductImages,
  makeProductSeoHeadTags,
  makeProductPageSchema,
} from "../utils/seoHelper"
import { convertFlatListToHierarchical } from "../utils/hierarchicalize"
import { slugify } from "../utils/slugify"
import { rawPrice as _rawPrice } from "../utils/pricesHelper"

import OfferRectangleIcon from "../images/Rectangle.png"
import IconArrowLinkWhite from "../images/icons-arrow-link-white.inline.svg"

import { ModalPortal } from "../portals/Modal.portal"
import IVendiIntroPanel from "../components/IVendiIntroPanel/IVendiIntroPanel"
import IVendiFinancialOffers from "../components/IVendiFinancialOffers/IVendiFinancialOffers"
import ProductAccessories from "../components/ProductAccessories/ProductAccessories"
import ProductImageSliderV2 from "../components/ProductImageSliderV2"
import Layout from "../components/Layout"
import { Tabs, Tab } from "../components/Tabs"
import Breadcrumb from "../components/UI/Breadcrumb/Breadcrumb"

import { BeforeAddToBasketNotification } from "./ProductUI/BeforeAddToBasketNotification"
import ProductColor from "./ProductUI/ProductColour"
import ProductSize from "./ProductUI/ProductSize"
import AddToBasket from "./ProductUI/AddToBasket"
import AddedToBasketModal from "./ProductUI/AddedToBasketModal"
import BasketErrorModal from "./ProductUI/BasketErrorModal"
import OutOfStock from "./ProductUI/OutOfStock"
import UpsellSection from "./ProductUI/UpsellSection"
import SpecTabContent from "./ProductUI/SpecTabContent"
import ProductPageAcfComponents from "./acfBlocks/ProductPageAcfComponents"
import ModalTriggerButton from "../components/UI/ModalTriggerButton"
import * as styles from "./simpleProduct.module.scss"
import Price from "./ProductUI/Price"
import {getFinanceOffer, convertDateToUkFormat} from "../utils/ivendi";
import { IVendiFinanceCheck } from "../components/IVendiIntroPanel/iVendiFinanceCheck"

const SimpleProduct = ({
  data,
  location,
  basket,
  isAddedToBasketModalVisible,
  hideAddedToBasketModal,
  isBasketError,
  hideBasketError,
  pageContext,
}) => {
  const product = JSON.parse(pageContext.product)
  const {
    id,
    name,
    productCategories,
    description,
    shortDescription,
    price,
    regularPrice,
    onSale,
    salePrice,
    stockStatus,
    stockQuantity,
    featuredImage,
    galleryImages,
    sku,
    attributes,
    upsell,
    product_videos: { youtubeVideoId },
    seo,
  } = product

  const sliderImages = getSliderImages(featuredImage, galleryImages)

  let specs
  if (attributes && attributes.nodes) {
    specs = getSpecs(attributes)
    product.colour = getSimpleProductColor(attributes)
    product.size = getProductSize(attributes)
  } else {
    console.warn(
      `# Crumbs! Simple product, ${name} (id: ${id}), has no attributes.`
    )
  }

  useEffect(() => {
    dataLayerUpdate("view_item", getRequiredProductDataForGA(product))
    return () => {}
  }, [])

  const categoryTree = convertFlatListToHierarchical(productCategories.nodes, {
    idKey: "id",
    childrenKey: "children",
    parentKey: "parentId",
  })

  let breadcrumbData
  let locationBreadcrumb

  locationBreadcrumb = prop("breadcrumbData", location.state)
  if (locationBreadcrumb) {
    breadcrumbData = location.state.breadcrumbData
  } else {
    breadcrumbData = getBreadcrumbDataFromCategoryObj(categoryTree)
  }

  const StockHandler = ({ stockQuantity, stockStatus }) => {
    const basketItem = basket.filter(item => item.itemId === id)
    let stockUpdate

    if (basketItem) {
      stockUpdate = prop("updatedStock", basketItem[0])
    }

    if (isBasketError) {
      return (
        <ModalPortal hide={hideBasketError} size="lg" centered={true}>
          <BasketErrorModal product={product} />
        </ModalPortal>
      )
    } else if (isAddedToBasketModalVisible) {
      return (
        <ModalPortal
          hide={hideAddedToBasketModal}
          size="lg"
          centered={true}
          heading="Added to basket">
          <AddedToBasketModal product={product} />
        </ModalPortal>
      )
    }

    if (
      (stockQuantity === null && stockStatus === "IN_STOCK") ||
      (stockQuantity && !stockUpdate) ||
      stockUpdate > 0
    ) {
      return (
        <AddToBasket
          product={product}
          buttonIcon={<IconArrowLinkWhite />}
          buttonTitle="Buy now"
          multiSelectEnabled={false}
          location={location}
        />
      )
    } else if(Boolean(Number(process.env.BACKORDER_ENABLED)) && stockStatus === "ON_BACKORDER") {
      return <AddToBasket
          product={product}
          buttonIcon={<IconArrowLinkWhite />}
          buttonTitle="Buy now"
          location={location}
          multiSelectEnabled={false}
      />
    } else {
      return <OutOfStock product={product} location={location}/>
    }
  }
  let featuredProductInformation = product?.beforeAddToBasketNotification?.productPromotions

  if (product.isUsed) {
    const featuredBoxes = buildUsedBikeFeaturedBoxes(attributes)
    if (featuredBoxes.length) {
      if (featuredProductInformation && featuredProductInformation.length) {
        featuredProductInformation.concat(featuredBoxes)
      } else {
        featuredProductInformation = featuredBoxes
      }
    }
  }


  const extraFee = product?.beforeAddToBasketNotification?.extraFee.feeValue
  const rawPrice = extraFee !== null ? ( + _rawPrice(price) + extraFee).toFixed(2) :_rawPrice(price)
  product.financeOffer = getFinanceOffer(product)

  const availability = getSeoAvailiability(stockStatus, stockQuantity)
  const seoImages = makeSeoProductImages(sliderImages)

  const productSeoHeadTags = makeProductSeoHeadTags(
    seo.fullHead,
    product.brand,
    availability.social,
    seoImages.social
  )
  const productPageSchema = makeProductPageSchema(
    seo.schema.raw,
    name,
    seoImages.schema,
    seo.metaDesc,
    sku,
    location.pathname,
    availability.schema,
    product.brand,
    rawPrice,
    onSale,
    breadcrumbData
  )

  const productImage = product.featuredImage
    ? product.featuredImage.node
      ? product.featuredImage.node.sourceUrl
      : null
    : null

  const iVendiFinancialOffersRef = useRef(null)
  const tabsRef = useRef(null)

  const isShippingEnabled = Boolean(Number(process.env.ENABLE_SHIPPING_FEE))
  const engineSize = getProductAttributeValue(
    product,
    "Displacement CC"
  )
  const handleIvendiPanelClick = () => {
    iVendiFinancialOffersRef.current.reset()
    tabsRef.current.selectTabAndFocus("tabFinancing")
  }

  return (
    <Layout
      location={location}
      seoHeadTags={productSeoHeadTags}
      schema={productPageSchema}
      spinBike={true}>
      <div className={styles.breadcrumbContainer}>
        {breadcrumbData && (
          <Breadcrumb data={breadcrumbData} className={styles.breadcrumb} />
          )}
      </div>
      <Container as={"section"} fluid={true} className={styles.introContainer}>
        <Row noGutters={true}>
          <Col
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="d-flex justify-content-end flex-row">
            <div className={styles.imageArea}>
              {sliderImages && (
                <div className={styles.sliderWrapper}>
                  <ProductImageSliderV2 product={product} />
                </div>
              )}
            </div>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="d-flex flex-row justify-content-start">
            <div className={styles.descriptionArea}>
              <div className={styles.tagContainer}>
                <div className={styles.productTag}>
                  {getProductPrimaryCategory(product)}
                </div>
                {engineSize !== "" && (
                  <div className={styles.productTag}>
                    {engineSize}
                  </div>
                )}
                {!isShippingEnabled && (
                  <div className={`${styles.productTag} border border-0 bg-danger`}>
                      <span className="text-white d-flex justify-content-center align-items-center font-weight-bold">Free Nationwide delivery</span>
                  </div>
                )}
              </div>
              <p className={styles.productBrand}>
                {getProductAttributeValue(product, "Brand")}
              </p>
              <h1 className={styles.productName}>{name}</h1>
              {regularPrice && (
                  <Price
                      onSale={onSale}
                      regularPrice={regularPrice}
                      salePrice={salePrice && salePrice}
                      promotionDetails={featuredProductInformation}
                  />
              )}

              {product.colour && (
                <div className="py-4">
                  <ProductColor
                    colour={product.colour}
                    allColours={pageContext.allColours}
                    showLabel={false}
                  />
                </div>
              )}

              {product.size && (
                <div className="py-4">
                  <ProductSize size={product.size} />
                </div>
              )}

              <BeforeAddToBasketNotification
                product={product}
                basket={basket}
              />

              <StockHandler
                stockStatus={stockStatus}
                stockQuantity={
                  stockQuantity !== undefined ? stockQuantity : null
                }
              />

              <div className={styles.alternativeButtonsContainer}>
                <Button
                  data-track-event="reserve"
                  variant="outline-primary"
                  className="px-4"
                  onClick={() =>
                    navigate("/bike-reservation", {
                      state: product,
                    })
                  }>
                  Reserve Now
                </Button>
                <ModalTriggerButton
                  btnClassName="px-4"
                  variant="outline-primary"
                  className="mr-lg-1"
                  buttonText="Part Exchange"
                  type="partExchange"
                  heading="Exchange your parts with us"
                  product={product}
                  location={location}
                  />
                <ModalTriggerButton
                  className="ml-md-1 ml-lg-0"
                  btnClassName="px-4"
                  variant="outline-primary"
                  buttonText="Book a test ride"
                  type="testRide"
                  heading="Book a free test ride"
                  product={product}
                  location={location}
                />

              </div>
              {product.financingWidgetIvendi.financingEnabled && (
                <>
                  <IVendiIntroPanel
                    capCode={product.financingWidgetIvendi.capCode}
                    used={product.isUsed}
                    cashPrice={rawPrice}
                    financeOffer={product.financeOffer}
                    currentOdometerReading={
                      product.financingWidgetIvendi.currentOdometerReading ? product.financingWidgetIvendi.currentOdometerReading : product.isUsed ? product.mileage : 50
                    }
                    registrationDate={
                      product.financingWidgetIvendi.registrationDate ? product.financingWidgetIvendi.registrationDate : product.isUsed ? product.firstRegistrationDate : null
                    }
                    vrm={product.financingWidgetIvendi.vrm ? product.financingWidgetIvendi.vrm : product.isUsed ? product.sku : null }
                    onClickDetails={handleIvendiPanelClick}
                    product={product}
                  />
                  <IVendiFinanceCheck
                    registrationDate={
                      product.financingWidgetIvendi.registrationDate
                      ? convertDateToUkFormat(product.financingWidgetIvendi.registrationDate)
                      : product.isUsed ? convertDateToUkFormat(product.firstRegistrationDate) : null
                    }
                    capCode={product.financingWidgetIvendi.capCode}
                    condition={product.isUsed ? 'used': 'NEW'}
                    currentOdometerReading={
                      product.financingWidgetIvendi.currentOdometerReading ? product.financingWidgetIvendi.currentOdometerReading : product.isUsed ? product.mileage : 50
                    }
                    vehicleImageUrl={productImage}
                    cashPrice={rawPrice}
                    vrm={ product.isUsed
                      ? product.financingWidgetIvendi.vrm ? product.financingWidgetIvendi.vrm : product.sku
                      : 'new'
                    }
                    product={product}
                  />
                </>
              )}

              {shortDescription && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: shortDescription,
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={styles.specificationsContainer}>
        <Tabs defaultSelectedEventKey="tab1" ref={tabsRef}>
          <Tab eventKey={"tab1"} title={"DESCRIPTION"}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Tab>
          <Tab eventKey={"tab2"} title={"GENERAL INFO"}>
            <SpecTabContent tabTitle="GENERAL INFO" product={product} />
          </Tab>
          <Tab eventKey={"tab3"} title={"DIMENSIONS"}>
            <SpecTabContent tabTitle="DIMENSIONS" product={product} />
          </Tab>
          <Tab eventKey={"tab4"} title={"TECH. SPEC"}>
            <SpecTabContent tabTitle="TECH. SPEC" product={product} />
          </Tab>
          <Tab eventKey={"tab5"} title={"ACCESSORIES"}>
            {product.externalRelatedProducts.products ? (
              <ProductAccessories
                ids={product.externalRelatedProducts.products}
              />
            ) : (
              <div>
                No accessories are available for this product at the moment.
              </div>
            )}
          </Tab>
          <Tab eventKey={"tabFinancing"} title={"FINANCING"}>
            {product.financingWidgetIvendi.financingEnabled ? (
              <IVendiFinancialOffers
                capCode={product.financingWidgetIvendi.capCode}
                used={product.isUsed}
                cashPrice={rawPrice}
                currentOdometerReading={
                  product.financingWidgetIvendi.currentOdometerReading ? product.financingWidgetIvendi.currentOdometerReading
                      : product.isUsed ? product.mileage : 50
                }
                registrationDate={
                  product.financingWidgetIvendi.registrationDate
                      ? convertDateToUkFormat(product.financingWidgetIvendi.registrationDate)
                      : product.isUsed ? product.firstRegistrationDate : null
                }
                vrm={product.isUsed
                    ? product.financingWidgetIvendi.vrm ? product.financingWidgetIvendi.vrm : product.sku
                    : 'new'}
                productImage={productImage}
                ref={iVendiFinancialOffersRef}
                url={location.href}
                product={product}
                financeOffer={product.financeOffer}
              />
            ) : (
              <div>
                Financing is not available on this product at the moment.
              </div>
            )}
          </Tab>
        </Tabs>
        {upsell.nodes && <UpsellSection upsells={upsell.nodes} />}
      </Container>
      <ProductPageAcfComponents data={data} />
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  basket: basketSelectors.getItems,
  isAddedToBasketModalVisible: basketSelectors.isAddedToBasketModalVisible,
  isBasketError: basketSelectors.isBasketError,
})
const mapDispatchToProps = {
  hideAddedToBasketModal: basketActions.hideAddedToBasketModal,
  hideBasketError: basketActions.hideBasketError,
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleProduct)

export const pageQuery = graphql`
  query GET_SIMPLE_PRODUCT_PAGE_CB($pageId: String!, $placeId: String!) {
      wpProduct( id: {eq: $pageId}) {
        ...ProductPageQueryCB
      }
    # TEMPORARY QUERYS
    sellYourBikeImg: file(name: { eq: "pp-default-bike-sell" }) {
      name
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          width: 1200
          quality: 75
          placeholder: NONE
        )
      }
    }
    googlePlacesPlace(place_id: { eq: $placeId }) {
      rating
      user_ratings_total
    }
    allGooglePlacesReview(sort: { order: DESC, fields: time }) {
      nodes {
        author_name
        text
        rating
        relative_time_description
      }
    }
  }
`
